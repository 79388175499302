@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');

body {
  background: linear-gradient(to right, rgb(0, 0, 0), #010d55);
  color: rgb(255, 255, 255)
}

.nav-buttons {
  display: flex;
}

@media (max-width: 420px) {
  .nav-buttons {
    display: none !important;
  }
}